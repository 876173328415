
import { useIntl } from 'react-intl';
import util from '../util';
import { useContext } from '../components/RenderContext';

const usePageTitle = () => {
  const intl = useIntl();

  const { settings: { displayName, location: { path } } } = useContext();
  const pageNameLocaleKey = util.getPageNameLocaleKey(path);
  const pageNameLocaleValue = (pageNameLocaleKey &&
    intl.formatMessage({ id: `page.title.${pageNameLocaleKey}` }))
    || '';
  const title = (pageNameLocaleValue && `${pageNameLocaleValue} | ${displayName}`) || displayName;
  return title;
};

export default usePageTitle;
