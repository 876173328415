import { ComponentType, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import usePageTitle from '../hooks/usePageTitle';


function withPageTitle<T extends {}>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const pageTitle = usePageTitle();
    return (
      <Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <WrappedComponent { ...props } />
      </Fragment>
    );
  };
}

export default withPageTitle;
